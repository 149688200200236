<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else-if="Object.keys(studentInfos).length" class="my-3">
      <h4 class="text-center my-3">Оценки OLDMOODLE</h4>

      <div class="card my-3">
        <div class="card-body">
          <div class="card-title mb-4">
            <h5>Информация о студенте</h5>
          </div>
          <div>
            <p>
              <b>ФИО:</b> {{ studentInfos?.last_name }} {{ studentInfos?.first_name }} {{studentInfos?.middle_name }}
            </p>
            <p><b>Баркод:</b> {{ studentInfos?.barcode }}</p>
            <p>
              <b>ОП:</b> {{studentInfos?.educationProgram?.education_speciality_code}}
              {{studentInfos?.educationProgram?.education_speciality_name}}
            </p>
            <p><b>Уровень обучения:</b> {{ studentInfos?.studyLevel?.name }}</p>
            <p><b>Форма обучения:</b> {{ studentInfos?.studyForm?.name }}</p>
            <p><b>Курс:</b> {{ studentInfos?.study_course }}</p>
            <p class="mb-0"><b>Группа:</b> {{ studentInfos?.studentGroups?.name }}</p>
          </div>
        </div>
      </div>

      <div v-if="oldRating.length||oldRatings.length">


        <div v-if="oldRatings.length">
          <DataTable :value="oldRatings" showGridlines stripedRows>
            <Column field="discipline_name" header="Дисциплина"></Column>
            <Column field="semester" header="Семестр"></Column>
            <Column field="credit" header="Кредит"></Column>
            <Column header="Общий балл">
              <template #body="{data}">
                <div class="p-inputgroup">
                  <InputNumber v-model="data.total" :disabled="!data?.total_edit_status" :min="0" :max="100"/>
                  <Button v-if="data.total_edit_status" icon="pi pi-check" :disabled="disabledSaveTotal"
                          @click="saveTotal(data.id)"/>
                  <Button v-else icon="pi pi-pencil" class="p-button-warning" @click="data.total_edit_status = 1"/>
                </div>
              </template>
            </Column>
            <Column field="gpa" header="GPA"></Column>
            <Column field="mark" header="Оценка"></Column>
            <Column header="Удаление">
              <template #body="{data}">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" :disabled="deletionRating"
                        @click="openDeleteRatingDialog(data.id, 'old_ratings')"/>
              </template>
            </Column>
          </DataTable>
        </div>

        <div class="mt-4" v-if="oldRating.length">
          <DataTable :value="oldRating" showGridlines stripedRows>
            <Column field="discipline_name" header="Дисциплина"></Column>
            <Column field="semester" header="Семестр"></Column>
            <Column field="credit" header="Кредит"></Column>
            <Column field="total" header="Общий балл"></Column>
            <Column header="Удаление">
              <template #body="{data}">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" :disabled="deletionRating"
                        @click="openDeleteRatingDialog(data.id, 'old_rating')"/>
              </template>
            </Column>
          </DataTable>
        </div>

        <ConfirmDialog group="deleteRatingDialog"></ConfirmDialog>
      </div>
      <div v-else>
        <h5 class="text-center mt-4">
          Здесь пусто
        </h5>
      </div>

    </div>
    <div v-else>
      <h5 class="text-center mt-4">
        Здесь пусто
      </h5>
    </div>


  </div>
</template>


<script>
  import httpClient from "@/services/http.service"

  export default {
    name: "OldRatings",
    data() {
      return {
        loading: true,
        barcode: +this.$route.query.barcode || 0,
        studentInfos: {},
        oldRating: [],
        oldRatings: [],
        deletionRating: false,
        disabledSaveTotal: false,
      }
    },
    methods: {
      async getStudentInfo(barcode) {
        try {
          const {status, data} = await httpClient.get(`student_data/student-data/get-info-by-barcode?barcode=${barcode}`)
          if (status === 200) {
            this.studentInfos = data.success
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      async getOldRatings(barcode) {
        try {
          const {status, data} = await httpClient.get(`old_ratings/old-student-ratings/get-rating-by-barcode?barcode=${barcode}`)
          if (status === 200) {
            this.oldRating = data.old_rating
            this.oldRatings = data.old_ratings
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      async deleteOldRating(id, table) {
        this.deletionRating = true
        let actionName = table === 'old_ratings' ? 'hide-old-moodle-student-ratings' : 'hide-old-moodle-full-old-rating'

        try {
          const {status} = await httpClient.delete(`old_ratings/old-student-ratings/${actionName}?id=${id}`)
          if (status === 200) {
            await this.getOldRatings(this.barcode)
            this.$message({text: `Успешно удалено`})
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }

        this.deletionRating = false
      },
      openDeleteRatingDialog(id, table) {
        const courseName = table === 'old_ratings' ? this.oldRatings.find(i => i.id == id)?.discipline_name : this.oldRating.find(i => i.id == id)?.discipline_name
        this.$confirm.require({
          group: 'deleteRatingDialog',
          message: `Вы точно хотите удалить дисциплину ${courseName} у студента`,
          header: 'Удаление дисциплины у студента',
          icon: 'pi pi-info-circle',
          acceptClass: 'p-button-danger',
          accept: async () => {
            await this.deleteOldRating(id, table)
          }
        })
      },
      async saveTotal(id) {
        this.disabledSaveTotal = true

        const index = this.oldRatings.findIndex(i => +i.id === +id)
        if (index >= 0) {
          try {
            const {status, data} = await httpClient.post(`old_ratings/old-student-ratings/update-grade?id=${id}`, {total: this.oldRatings[index].total})
            if (status === 200) {
              this.oldRatings[index] = data
              this.$message({text: `Успешно изменено`})
            }
          } catch (e) {
            this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
          }
        }

        this.disabledSaveTotal = false
      }
    },
    async mounted() {
      if (this.barcode) {
        await this.getStudentInfo(this.barcode)
        await this.getOldRatings(this.barcode)
      }
      this.loading = false
    }
  }
</script>


<style scoped>

</style>